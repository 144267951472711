<template>
    <div class="allcase-page" v-loading="downLoading">
        <div class="user-sel">
            <el-input size="small" placeholder="请输入案件编号检索" v-model="selCon.reportCaseNum"
                style="width:280px;margin-right:10px;" clearable></el-input>
            <el-date-picker
                v-model="selCon.outCaseTime"
                type="daterange"
                size="small"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                style="margin-right:10px;">
            </el-date-picker>
            <el-select v-model="selCon.status" placeholder="请选择案件状态" size="small" style="margin-right:10px;" clearable>
                <el-option key="待接单" label="待接单" value="0"></el-option>
                <el-option key="维修中" label="维修中" value="2"></el-option>
                <el-option key="已维修" label="已维修" value="3"></el-option>
                <el-option key="已核定" label="已核定" value="4"></el-option>
                <el-option key="已复核" label="已复核" value="5"></el-option>
            </el-select>
            <el-input size="small" placeholder="出险地点" v-model="selCon.outDangerAddr"
                style="width:280px;margin-right:10px;" clearable></el-input>
            <el-button icon="el-icon-search" type="primary" size="small" @click="userSel">查询</el-button>
        </div>
        <div>
            <el-button icon="el-icon-plus" type="success" size="small" @click="addCase">新增案件</el-button>
            <el-button size="small" icon="el-icon-refresh" @click="refreshPage">刷新</el-button>
            <el-radio-group v-model="belongArea" style="margin-left: 30px;" @input="areaChange">
                <el-radio label="全部">全部案件</el-radio>
                <el-radio label="温州">温州</el-radio>
                <el-radio label="台州">台州</el-radio>
                <el-radio label="萧山">萧山</el-radio>
                <el-radio label="绍兴">绍兴</el-radio>
                <el-radio label="其它">其它</el-radio>
            </el-radio-group>
        </div>
        <div class="user-table">
            <el-table :data="tableData" size="medium" border style="width: 100%">
                <el-table-column type="index" label="序号" width="50" align="center">
                </el-table-column>
                <el-table-column prop="reportCaseNum" label="报案号" width="160" align="center">
                </el-table-column>
                <el-table-column prop="belongArea" label="区域" width="60" align="center">
                </el-table-column>
                <el-table-column prop="reportTime" label="报案时间" width="110" align="center">
                </el-table-column>
                <el-table-column prop="timeLen" label="处理时长" width="78" align="center">
                    <template slot-scope="scope">
                        <span :style="(scope.row.status!=5)?'color:red;':''">{{ scope.row.timeLen }}天</span>
                    </template>
                </el-table-column>
                <el-table-column prop="outDangerTime" label="出险时间" width="110" align="center">
                </el-table-column>
                <el-table-column prop="outDangerAddr" label="出险地点" align="center" show-overflow-tooltip>
                </el-table-column>
                <!-- <el-table-column prop="surveyor" label="查勘员" width="120" align="center">
                </el-table-column> -->
                <el-table-column prop="createTime" label="创建时间" width="160" align="center">
                </el-table-column>
                <el-table-column prop="status" label="案件状态" width="120" align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==0" style="color:#ff0000;">未派单</span>
                        <span v-else-if="scope.row.status==1" style="color:#fa7122;">已派单</span>
                        <span v-else-if="scope.row.status==2" style="color:#E6A23C;">维修中</span>
                        <span v-else-if="scope.row.status==3" style="color:#409EFF;">已维修</span>
                        <span v-else-if="scope.row.status==4" style="color:#fa7122;">已核定</span>
                        <span v-else-if="scope.row.status==5" style="color:#67C23A;">已复核</span>
                    </template>
                </el-table-column>
                <el-table-column prop="repairPeoNames" label="维修工" show-overflow-tooltip align="center" width="160">
                </el-table-column>
                <el-table-column prop="caseDesc" label="案件说明" show-overflow-tooltip header-align="center">
                </el-table-column>
                <el-table-column label="操作" header-align="center" min-width="240" width="280" align="center" fixed="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="success" @click="initRepairPeo(scope.row)" v-if="scope.row.status==0">派单</el-button>
                        <el-button size="mini" type="primary" @click="toDetail(scope.row,'detail')">查看详情</el-button>
                        <el-button size="mini" type="success" @click="toDetail(scope.row,'check')" v-if="scope.row.status==3&$store.state.user.userType=='02'">核定</el-button>
                        <el-button size="mini" type="danger" @click="toDetail(scope.row,'review')" v-if="scope.row.status==4&$store.state.user.userType=='01'">复核</el-button>
                        <el-button size="mini" type="warning" @click="downLoad(scope.row)" v-if="scope.row.status==5&&($store.state.user.userType=='01'||$store.state.user.userType=='02')">打包下载</el-button>
                        <el-button size="mini" type="warning" @click="editCase(scope.row.id)" v-if="scope.row.status==0">修改</el-button>
                        <el-button size="mini" type="danger" @click="delCase(scope.row.id,scope.row.status)" v-if="(scope.row.status==0)||(scope.row.status!=4&scope.row.status!=5&$store.state.user.userType=='01')">删除</el-button>
                        <el-button size="mini" type="danger" @click="backCase(scope.row.id,'维修工？',2)" v-if="scope.row.status==4&&$store.state.user.userType=='02'">退回</el-button>
                        <el-dropdown @command="e=>handleCommand(scope.row.id,e)" size="small" style="margin-left: 10px;" v-if="scope.row.status==5&&$store.state.user.userType=='01'">
                            <el-button type="primary" size="mini">
                                退回<i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="a">退回内勤</el-dropdown-item>
                                <el-dropdown-item command="b">退回维修工</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination background style="margin-top:20px;text-align:right;" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :current-page.sync="page.current" :page-sizes="[10, 50, 100, 200]"
                    :page-size="page.pageSize" layout="total,sizes, prev, pager, next" :total="page.total">
                </el-pagination>
        </div>
        <el-dialog title="案件分配" :visible.sync="dispatchDialog" custom-class="my-dialog-style" @close="clearDisInfo">
            <div class="dis-case-info">
                <el-descriptions class="margin-top" title="案件基本信息" :column="3" :size="'medium'">
                    <el-descriptions-item label="案件编号">{{disCaseObj.caseNum}}</el-descriptions-item>
                    <el-descriptions-item label="报案号">{{disCaseObj.reportCaseNum}}</el-descriptions-item>
                    <el-descriptions-item label="报案时间">{{disCaseObj.reportTime}}</el-descriptions-item>
                    <el-descriptions-item label="被保险人">{{disCaseObj.insurePeo}}</el-descriptions-item>
                    <el-descriptions-item label="出险时间">{{disCaseObj.outDangerTime}}</el-descriptions-item>
                    <el-descriptions-item label="出险单位">{{disCaseObj.outDangerUnit}}</el-descriptions-item>
                    <el-descriptions-item label="出险地点">{{disCaseObj.outDangerAddr}}</el-descriptions-item>
                </el-descriptions>
            </div>
            <el-table ref="multipleTable" :data="peoList" size="medium" border style="width: 100%" @selection-change="selectionChange">
                <el-table-column type="selection" align="center" width="55" label="选择">
                </el-table-column>
                <el-table-column prop="name" label="姓名" width="160" align="center">
                </el-table-column>
                <el-table-column prop="userSex" label="性别" width="100" align="center">
                </el-table-column>
                <el-table-column prop="userTel" label="联系方式" width="120" align="center">
                </el-table-column>
                <el-table-column prop="userDesc" label="擅长领域" align="center">
                </el-table-column>
            </el-table>
            <div style="margin-top:10px;">
                <span>已选择维修工：</span>
                <span style="margin-right:10px;" v-for="item in repairUserList" :key="item.id">{{item.name}}</span>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dispatchDialog = false" size="small">取 消</el-button>
                <el-button type="primary" @click="dispatchCase" size="small">确定分配</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    name: "AllCase",
    data() {
        return {
            tableData: [],
            belongArea:"全部",
            page:{
                current:1,
                pageSize:10,
                total:0
            },
            peoList:[],//维修工list
            selCon: {},
            dispatchDialog:false,
            disCaseObj:{},//待派单的案件对象
            repairUserList:[],//选择的要分配的维修工
            userinfo:null,
            downLoading:false,//打包下载按钮的加载动画
        }
    },
    created() {
        this.initPage();
        this.userinfo = this.$store.state.user;
    },
    methods: {
        /**页面数据加载 */
        initPage(){
            const that = this;
            let _params = that.page;
            that.request.get("case/page",_params).then(res => {
                if(res.code===200){
                    that.tableData = res.data.records;
                    that.page.total = res.data.total;
                }
            });
        },
        /**区域选择切换时的触发事件 */
        areaChange(e){
            const that = this;
            if(e!='全部'){
                that.selCon.belongArea = e;
            }else{
                that.selCon.belongArea = null;
            }
            that.userSel();

        },
        /**初始化维修工的列表信息。用户点击《派单》时触发加载。 */
        initRepairPeo(e){
            const that =this;
            that.request.get("sysuser/getRepairPeo").then(res=>{
                if(res.code === 200){
                    that.peoList = res.data;
                    that.dispatchDialog=true;
                    that.disCaseObj = e;
                }
            });
        },
        /**刷新当前页面 */
        refreshPage(){
            location.reload();
        },
        /**新增页面跳转 */
        addCase(){
            this.$router.push("/setting/case/add");
        },
        /**修改案件信息 */
        editCase(id){
            this.$router.push({path:"/setting/case/add",query: { id:id}})
        },
        /**删除案件 */
        delCase(id,status){
            const that = this;
            var tips = "此操作将永久删除该数据, 是否继续?";
            if(status!=0){
                tips = "此操作将删除该案件已维修的过程数据（客户、电器、图片等）, 是否继续?";
            }
            that.$confirm(tips, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.get("case/delete",{id:id}).then(res =>{
                    if(res.code===200){
                        that.initPage();
                    }
                });
            }).catch(() => {
            });
        },
        /**内勤退回案件 */
        backCase(caseId,backRole,status){
            const that  =this;
            let _params = {
                caseId:caseId,
                status:status,
                user:that.$store.state.user,
                stage:backRole
            }
            that.$confirm('是否确认将该案件将退回给'+backRole, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                that.request.post("case/backCase",_params).then(res=>{
                    if(res.code === 200){
                        that.initPage();
                    }
                })
            }).catch(() => {});
        },
        /**领导退回案件 */
        handleCommand(caseId,e){
            console.log(caseId,e);
            const that = this;
            if(e==='a'){
                that.backCase(caseId,'内勤？',3);
            }else if(e==='b'){
                that.backCase(caseId,'维修工？',2);
            }
        },
        /**维修工列表选择操作 */
        selectionChange(row){
            this.repairUserList = row;
        },
        /**派单业务逻辑：选择案件、选择人员之后即可派单 */
        dispatchCase(){
            const that = this;
            if(that.repairUserList.length!=0&&that.disCaseObj.id){
                var _params = {
                    caseId:that.disCaseObj.id,
                    caseNum:that.disCaseObj.caseNum,
                    repairUserList:that.repairUserList,
                    remark:that.disCaseObj.caseNum+"被分配给"
                }
                that.request.post("case/dispatchV2",_params).then(res=>{
                    console.log(res);
                    if(res.code===200){
                        that.dispatchDialog = false;
                        that.initPage();
                    }
                });
            }else{
                that.$notify.error({
                    title: '错误',
                    message: '维修工必须要选择！！！'
                });
            }
        },
        /**分配完成、弹窗关闭清空相关信息 */
        clearDisInfo(){
            this.repairUserList=[];
            this.disCaseObj={};
        },
        /**打包下载资料 */
        downLoad(e){
            const that = this;
            that.downLoading = true;
            axios({
                url:"/api/case/pkgDownV3",
                params:{caseId:e.id},
                headers: {'token': sessionStorage.getItem("token")},
                responseType:"blob"
            }).then(res=>{
                let fileName = res.headers["content-disposition"].split(";")[1].split("filename=")[1];
                fileName = "[案件资料]"+fileName;
                if (res.status === 200) {
                    let url = window.URL.createObjectURL(new Blob([res.data], { type: "application/octet-stream" }))
                    let link = document.createElement('a')
                    link.style.display = 'none'
                    link.href = url
                    link.setAttribute('download', window.decodeURI(fileName))
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link); // 下载完成移除元素
                    window.URL.revokeObjectURL(url); // 释放掉blob对象
                    that.downLoading = false;
                }
            });
        },
        /**
         * 查看案件详情
         * 2023-03-16 更改路由信息
         */
        toDetail(e,flag){
            console.log(e);
            this.$router.push({path:"/case/detail/index",query: { id:e.id,flag:flag}})
        },
        /**用户检索 */
        userSel() {
            console.log(this.selCon);
            const that = this;
            that.page = Object.assign(that.page,that.selCon);
            console.log(that.page);
            that.initPage();
        },
        /**页面条数变化事件 */
        handleSizeChange(e) {
            this.page.pageSize = e;
            this.page.current = 1;
            this.initPage();
        },
        /**当前页变化 */
        handleCurrentChange() {
            this.initPage();
        }
    }
}
</script>
<style lang="less" scoped>
.allcase-page{
    .user-sel {
        display: flex;
        margin-bottom: 15px;
    }

    .user-table {
        margin-top: 15px;
    }
}
</style>